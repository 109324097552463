
.profile .myGames {
    width: 80%;
    margin: auto
}
.profile .myGames h3{
    font-size: 24px;
    font-weight: bold
}

.profile .myGames h3{
    font-size: 24px;
    font-weight: bold
}


