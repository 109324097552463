@import url(https://fonts.googleapis.com/css?family=Muli);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.intro {
    position: relative;
    height: 85%;
    background-image: url(/static/media/hero.9d21600a.jpg);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.intro .introText {
    text-align: right;
    max-width: 40%;
    position: absolute;
    right: 5%;
    top: 30%;
    color: #fff;
    font-size: 24px;
    text-shadow: #323232 0 1px 3px;
}

.intro .introText .signup {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    border-radius: 5px;
    background-color: #6c8784;
    padding: 10px 20px;
    text-decoration: none;
    line-height: 2;
    text-transform: uppercase;
    text-shadow: #323232 0 1px 3px
}

.intro .introText .signup:hover {
    background-color: #8B0000;
    cursor: pointer;
}

.mainHead {
    position: relative;
    height: 60px;
}

.mainHead a{
    font-weight: bold;
    text-decoration: none;
}

.mainHead h1 {
    line-height: 2;
    font-size: 24px;
    display:inline-block;
}

.mainHead nav {
    position: absolute;
    right: 3%;
    top:0
}

.mainHead nav a {
    line-height: 3;
    font-weight: bold;
    font-size: 16x;
    display:inline-block;
}

.mainHead h1 {
    position: absolute;
    top:0;
    left: 3%
}

.mainHead nav ul li {
    display: inline-block;
    margin-right: 20px;
}

.mainHead .fa-graduation-cap{
    color: #0E5CB4;
    margin-right: 5px;
    font-size: 28px;
}
.howTo {
    font-size: 24px;
    background-color: #016087;
    margin-top: 5%;
    color: #fff;
    padding: 2%;
}

.howTo a {
    text-decoration: none
}

.openGame li {
    font-size: 24px
}

.openGame a {
    font-size: 16px;
    background-color:#373f46;
    color: #fff;
    border-radius: 5px;
    padding: 4px;
    margin-left: 5px;
    text-transform: capitalize;
    text-decoration: none;
    vertical-align: top
}
.topExplorer h2 {
    padding: 10px;
    text-shadow: 1px 1px 1px rgba(255, 0, 0, .4);
    background-color: rgba(255,0,0,0.1);
    color: #000
}

.top5 li {
    font-size: 22px
}

.joinGame {
    width: 300px;
    margin: 20px auto;
    text-align: center
}
.joinGame button {
    font-size: 14px;
    background-color:#373f46;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 10px;
    text-transform: capitalize
}

.joinGame button:hover {
    background-color: #8B0000;
    cursor: pointer;
}
.logIn {
    background: -webkit-linear-gradient(#ffffff 90%, rgba(255,255,255,0) 0) 0 0,
-webkit-radial-gradient(circle closest-side, #FFFFFF 98%, rgba(255,255,255,0) 0) 50% 0 rgba(0, 119, 204, 0.363);
    background: linear-gradient(#ffffff 90%, rgba(255,255,255,0) 0) 0 0,
radial-gradient(circle closest-side, #FFFFFF 98%, rgba(255,255,255,0) 0) 50% 0 rgba(0, 119, 204, 0.363);
background-size: 100%;
    height: 90vh;
    display: grid;
    padding:0
}

.signUp {
    background: -webkit-linear-gradient(#ffffff 90%, rgba(255,255,255,0) 0) 0 0,
-webkit-radial-gradient(circle closest-side, #FFFFFF 98%, rgba(255,255,255,0) 0) 50% 0 rgba(93, 177, 159, 0.363);
    background: linear-gradient(#ffffff 90%, rgba(255,255,255,0) 0) 0 0,
radial-gradient(circle closest-side, #FFFFFF 98%, rgba(255,255,255,0) 0) 50% 0 rgba(93, 177, 159, 0.363);
background-size: 86%;
background-repeat: repeat-x;
    height: 90vh;
    display: grid;
    padding:0
}

.logIn form, .signUp form, .userProfile form {
    font-size: 24px;
    line-height: 2;
    text-align: left;
    margin: auto;
    width: 300px
}

.logIn input, .signUp input, .createGame input, .userProfile input, .joinGame input, .liveGame .liveGameAnswer input {
    font-size: 24px;
    width: 290px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    padding: 5px
}

.logIn label, .signUp label, .createGame label, .userProfile label, .joinGame label, .liveGame .liveGameAnswer label {
    font-size: 24px;
    line-height: 2;
    text-align: left;
    margin: auto;
    width: 300px;
    display: block
}


.logIn input:hover, .signUp input:hover, .createGame input:hover, .userProfile input:hover {
    box-shadow: 2px 2px 40px 2px rgba(0, 0, 255, .1);
}

.logIn button, .signUp button, .createGame button, .userProfile button, .howTo a, .liveGame .liveGameAnswer button {
    font-size: 24px;
    background-color:#6c8784;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 20px;
}

.logIn button:hover, .signUp button:hover, .createGame button:hover, .userProfile button:hover, .howTo a:hover, .liveGame .liveGameAnswer button:hover {
    background-color: #8B0000;
    cursor: pointer;
}

.form-error, .form-warning, .message{
    font-size: 14px;
    color: #8B0000;
    display: block
}
.liveGame{
    width: 80%;
    margin: 30px auto;
    background-color: #016087;
    border-radius: 10px;
    color: #fff;
    text-shadow: #323232 0 1px 3px;
    font-size: 22px;
    position: relative;
}

.liveGame h2{
    font-size: 30px;
    padding: 2%
}

.liveGame button{
    font-size: 18px;
    background-color:#56198B;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 20px;
}

.liveGame button:hover{
    background-color: #8B0000;
    cursor: pointer;
}

.liveGame .preGame {
    padding-top: 50px
}

.liveGame .playerStatus {
    margin: 20px auto;
    font-size: 18px;
    line-height: 1;
}

.liveGame .question {
    font-size: 28px;
    margin: 30px auto;
}

.liveGame .liveGameAnswer input {
    margin-bottom: 10px
}

.liveGame .liveGameAnswer button {
    display: block;
    margin: auto
}

.profile .myGames {
    width: 80%;
    margin: auto
}
.profile .myGames h3{
    font-size: 24px;
    font-weight: bold
}

.profile .myGames h3{
    font-size: 24px;
    font-weight: bold
}



.resultAndPool {
    width:100%;
    margin:auto;
    box-sizing: border-box
}

.resultAndPool .wordResult, .resultAndPool .wordsPool{
    width: 41%;
    margin: 5% 2%;
    border: 1px solid green;
    border-radius: 5%;
    padding: 2%;
    display: inline-block;
    min-height: 60%;
    vertical-align: top;
    text-align: left
}

.resultAndPool .wordResult:hover, .resultAndPool .wordsPool:hover{
    box-shadow: 2px 2px 40px 2px rgba(0, 0, 255, .1);
}

.resultAndPool .wordsPool .correctAnswer{
    font-size: 24px;
    font-weight: bold;
    display: block;
    text-transform: capitalize
}

.resultAndPool .wordsPool .question{
    margin-left: 25px;
}

.remove:hover {
    text-decoration: line-through;
    cursor: no-drop;
    box-shadow: 2px 2px 40px 2px rgba(255, 0, 0, 0.5);
}

.resultAndPool h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 5px;
    text-shadow: rgba(255, 0, 0, 0.5) 0 0 90px;
    margin-bottom: 15px
}

.wordResult .speech {
    margin-left: 5px;
    color: #4a7d95
}

.wordResult .example {
    color: #225f73;
    font-size: 18px;
    display: block;
    margin-left: 20px
}

.wordResult .definition {
    color: #265667;
}

.wordResult .mainWord {
    color: #303336;
    font-size: 34px;
    font-weight: bold;
    text-transform: capitalize
}

.wordResult .add {
    cursor: copy
}

.wordResult .add:hover {
    cursor: copy;

    box-shadow: 2px 2px 40px 2px rgba(0, 0, 255, .5);
}
/* CSS reset */
html, body, div, span, h1, h2, pre, a, img, ol, ul, li, section, footer, .resultAndPool {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  box-sizing: border-box;
}
  
html, body, #root, main, .mainPage {
  height: 100%
}

body {
  font-family: 'Muli', sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
  line-height: 2em;
  background-color: #fafbfa
}

section {
  padding: 2% 0;
}

section li{
  list-style: none
}

legend, h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold
}

a {
  color: #07C
}

@media (orientation: portrait) {
    .intro {
        position:static;
        background-image: url(/static/media/hero.9d21600a.jpg);
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
    }

    .logIn, .signUp {
        background: none;
        height:unset
    }
    .intro .introText {
        max-width: 100%;
        text-align: center;
        margin-top: 65%;
        position: static;
        min-height: 400px;
        right: 5%;
        top: 30%;
        color: #000;
        font-size: 24px;
        text-shadow: #323232 0 1px 1px
    }
    .howTo {
        padding: 5%;
    }

    .liveGame {
        width: 95%
    }

    .mainHead nav a {
        line-height: 2
    }
}

@media screen and (max-width: 760px) {
    .intro {
        height: 100%
    }
    .intro .introText {
        font-size: 20px;
        top: 10%;
    }
}

@media screen and (max-width: 620px) {
    
    .mainHead {
        margin-bottom: 80px;
    }
    .mainHead nav {
        position: static;
    }
    
    .mainHead h1 {
        position: static;
    }

    .resultAndPool .wordResult, .resultAndPool .wordsPool {
        width: 90%;
        display: block;
        margin: auto;
        margin-top: 50px
    }
}
