.logIn {
    background: linear-gradient(#ffffff 90%, rgba(255,255,255,0) 0) 0 0,
radial-gradient(circle closest-side, #FFFFFF 98%, rgba(255,255,255,0) 0) 50% 0 rgba(0, 119, 204, 0.363);
background-size: 100%;
    height: 90vh;
    display: grid;
    padding:0
}

.signUp {
    background: linear-gradient(#ffffff 90%, rgba(255,255,255,0) 0) 0 0,
radial-gradient(circle closest-side, #FFFFFF 98%, rgba(255,255,255,0) 0) 50% 0 rgba(93, 177, 159, 0.363);
background-size: 86%;
background-repeat: repeat-x;
    height: 90vh;
    display: grid;
    padding:0
}

.logIn form, .signUp form, .userProfile form {
    font-size: 24px;
    line-height: 2;
    text-align: left;
    margin: auto;
    width: 300px
}

.logIn input, .signUp input, .createGame input, .userProfile input, .joinGame input, .liveGame .liveGameAnswer input {
    font-size: 24px;
    width: 290px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    padding: 5px
}

.logIn label, .signUp label, .createGame label, .userProfile label, .joinGame label, .liveGame .liveGameAnswer label {
    font-size: 24px;
    line-height: 2;
    text-align: left;
    margin: auto;
    width: 300px;
    display: block
}


.logIn input:hover, .signUp input:hover, .createGame input:hover, .userProfile input:hover {
    box-shadow: 2px 2px 40px 2px rgba(0, 0, 255, .1);
}

.logIn button, .signUp button, .createGame button, .userProfile button, .howTo a, .liveGame .liveGameAnswer button {
    font-size: 24px;
    background-color:#6c8784;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 20px;
}

.logIn button:hover, .signUp button:hover, .createGame button:hover, .userProfile button:hover, .howTo a:hover, .liveGame .liveGameAnswer button:hover {
    background-color: #8B0000;
    cursor: pointer;
}

.form-error, .form-warning, .message{
    font-size: 14px;
    color: #8B0000;
    display: block
}